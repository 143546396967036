import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Input from '../../../../components/input';
import { FlatButton } from '../../../../components/button';
import Checkbox from '../../../../components/checkbox';

import { DEFAULTTIME, DATE } from '../../../../constants/time';

import TRANSFERTYPES from '../../../../bi/constants/transferTypes';
import { FIELDS, PRICEFIELDSSHORT, PRICELABELS } from '../../../../bi/constants/trips';
import COMPONENTS from '../../../../bi/constants/components';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';

import { isMandatory } from '../../../../bi/utils/account';
import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import {
  findDepartments,
  generateEmployeePlaceholder,
  validateDateAndTime,
} from '../../../../bi/utils/trip';
import { filterOnlyNumberArray } from '../../../../bi/utils/array';
import {
  applyAnalytics,
  removeAnalytics,
  validateSingleAnalytics,
  validateAnalytics,
} from '../../../../bi/utils/customAnalytics';
import {
  getMoment,
  getDate,
  isBeforeDateParameter,
  isMoment,
  getTimeFromTimezoneFormat,
  getTimeFromSpaceFormat,
} from '../../../../bi/utils/formatDate';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import EmployeeSuggest from '../../../../components/employee';

import SelectProject from '../selectProject';
import renderFundsForm from '../form';
import SelectDepartment from '../selectDepartment';
import SelectCompany from '../selectCompany';

import { CustomFields } from './components/CustomFields';
import { CancellationFields } from './components/CancellationFields';
import { OperationsFields } from './components/OperationsFields';
import { EditPrice } from './components/EditPrice';
import { EmployeeFields } from './components/EmployeeFields';
import { OrderIdFields } from './components/OrderIdFields';
import { ViewersFields } from './components/ViewersFields';

import styles from '../../styles/form.module.scss';


const MAX_LENGTH = 40;
const MAX_LENGTH_TABLE = 100;
const MAX_LENGTH_ADDRESS = 1000;

const LABELS = {
  STARTPLACE: 'Пункт отправления',
  ENDPLACE: 'Пункт прибытия',
  DESCRIPTION: 'Наименование изменения',
  BASEPRICE: 'Цена поставщика',
  COMMISIONPRICE: 'Наценка Smartway',
  LOCATIONADDRESS: 'Улица и номер дома пункта отправления',
  DESTINATIONADDRESS: 'Улица и номер дома пункта прибытия',
  CAPACITY: 'Количество посадочных мест',
  NOTE: 'Заметки',
  TABLESIGNATURE: 'Надпись на табличке',
  CONFIRM: 'Подтвердить изменения',
  INCLUDETABLET: 'Встреча с табличкой',
};

const SERVICE_NAME = 'transfer';

class TransferForm extends Component {
  static propTypes = {
    transfer: PropTypes.object,
    tripService: PropTypes.object,
    companies: PropTypes.array,
    onConfirm: PropTypes.func,
    projects: PropTypes.array,
    isEditing: PropTypes.bool,
    isCopy: PropTypes.bool,
    mandatoryProject: PropTypes.array,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    airService: PropTypes.object.isRequired,
    addTrip: PropTypes.func,
    guidService: PropTypes.object.isRequired,
  };

  static defaultProps = {
    transfer: [],
    tripService: [],
    projects: [],
    companies: [],
    onConfirm: () => {},
    isEditing: false,
    isCopy: false,
    mandatoryProject: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    addTrip: () => {},
  };

  constructor(props) {
    super(props);

    this.setTransfer();

    this.carClassOptions = props.tripService.getTransferCarClass();
  }

  componentDidMount() {
    const { isEditing, tripService } = this.props;
    this.unsubscribe = tripService.subscribe(this.updateTripData);

    if (isEditing) {
      this.setDepartment();
    }

    this.handleProviderFocus();
  }

  UNSAFE_componentWillReceiveProps({ transfer }) {
    if (transfer.OrderTripItemId !== this.state.OrderTripItemId) {
      this.setTransfer();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ employeesList }) => this.setState({ employeesList })

  setDepartment = async () => {
    const { transfer: { Employees, CompanyId }, tripService: { getEmployeeDataByID } } = this.props;
    const currentEmployee = await getEmployeeDataByID(Employees[0].Id);
    this.setState({ Departments: findDepartments(currentEmployee, CompanyId) });
  }

  setTransfer() {
    const {
      transfer: {
        JsonData,
        OrderTripItemId,
        ServiceType,
        PriceDetails,
        Employees,
        CompanyId,
        ProjectId,
        EmployeeId,
        DepartmentId,
        ProviderName,
        UserAnalytics = [],
      },
      transfer,
      isEditing,
      isCopy,
      mandatoryProject,
    } = this.props;

    // Редактирование при создании поездки
    if (transfer.isEdit) {
      this.state = transfer;
      this.setMomentDates(transfer.JsonData, transfer.isEdit);
      return;
    }

    const jsonData = typeof JsonData === 'string' ? JSON.parse(JsonData) : JsonData;

    this.state = {
      UserAnalytics,
      OrderTripItemId,
      ServiceType,
      PriceDetails,
      isReturnFunds: true,
      showCustomFields: {
        StartPlace: true,
        EndPlace: true,
      },
      Departments: [],
      Providers: '',
      mandatoryProject: [],
      validation: {
        CompanyId: '',
        ProjectId: '',
        DepartmentId: '',
        Description: '',
        Employees: '',
        CheckinDate: '',
        CheckinTime: '',
        CheckoutDate: '',
        CheckoutTime: '',
        ArrivalCountry: '',
        ArrivalCity: '',
        DepartureCountry: '',
        DepartureCity: '',
        CancellationDate: '',
        CancellationTime: '',
        StartPlace: '',
        EndPlace: '',
        LocationAddress: '',
        DestinationAddress: '',
        Base: '',
        Fee: '',
        Tax: '',
        Commission: '',
        Providers: '',
        CarriageNumber: '',
        analytics: {},
      },
      employeesListForPick: [{ id: 0 }],
      employeesList: [],
      providersList: [],
      swapDestinationClicked: true,
    };

    const {
      StartPlace,
      EndPlace,
      CancellationDate,
      CheckinDate,
      CheckoutDate,
      PriceDetails: PriceDetailsObj,
      IncludeTablet,
    } = jsonData;

    if (isEditing) {
      const selectedEmployeesIds = Employees.map(({ Id }) => Id);
      const employeesListForPick = Employees.map((emp, index) => ({ ...emp, id: index }));

      const StartPlaceAddressLabel = StartPlace.Address ? StartPlace.Address : '';
      const EndPlaceAddressLabel = StartPlace.Address ? EndPlace.Address : '';
      const CancellationTime = getTimeFromTimezoneFormat(CancellationDate);
      const CheckinTime = getTimeFromTimezoneFormat(CheckinDate);
      const CheckoutTime = getTimeFromTimezoneFormat(CheckoutDate);
      const newMandatory = isMandatory(mandatoryProject, CompanyId);

      this.state = {
        ...this.state,
        Description: '',
        CancellationDate: '',
        CancellationTime: '',
        mandatoryProject: newMandatory,
        Status: 1,
        Penalties: {
          Enabled: false,
          Description: '',
          Penalties: 0,
          Tax: 0,
        },
        ReturnFunds: {
          Enabled: false,
          Base: 0,
          Description: '',
        },
        Surcharges: {
          Enabled: false,
          Base: 0,
          Commission: 0,
          Description: '',
        },
        EmployeeId: EmployeeId || 0,
        CompanyId: CompanyId || 0,
        ProjectId: ProjectId || 0,
        DepartmentId: DepartmentId || 0,
        Departments: [],
        JsonData: {
          ...jsonData,
          ProviderName,
          CancellationTime,
          CheckinTime,
          CheckoutTime,
          IncludeTablet: IncludeTablet || false,
          PriceDetails: {
            Base: PriceDetailsObj.Base,
            BasePrice: PriceDetailsObj.BasePrice,
            Fee: PriceDetailsObj.Fee,
            Tax: PriceDetailsObj.Tax,
            Commission: PriceDetailsObj.Commission,
          },
          StartPlace: {
            ...StartPlace,
            TransferPlaceType: StartPlace.Type,
            Address: {
              label: StartPlaceAddressLabel,
              selected: {},
              suggests: [],
            },
          },
          EndPlace: {
            ...EndPlace,
            TransferPlaceType: EndPlace.Type,
            Address: {
              label: EndPlaceAddressLabel,
              selected: {},
              suggests: [],
            },
          },
        },
        from: {
          label: StartPlace.Name,
          selected: {},
          suggests: [],
        },
        to: {
          label: EndPlace.Name,
          selected: {},
          suggests: [],
        },
        selectedEmployeesIds,
        employeesListForPick,
        employeesList: [],
      };
    } else if (isCopy) {
      const StartPlaceAddressLabel = StartPlace.Address ? StartPlace.Address : '';
      const EndPlaceAddressLabel = StartPlace.Address ? EndPlace.Address : '';
      const CancellationTime = getTimeFromSpaceFormat(CancellationDate);
      const CheckinTime = getTimeFromSpaceFormat(CheckinDate);
      const CheckoutTime = getTimeFromSpaceFormat(CheckoutDate);

      this.state = {
        ...this.state,
        Status: 0,
        CompanyId: 0,
        ProjectId: 0,
        DepartmentId: 0,
        Departments: [],
        JsonData: {
          ...jsonData,
          ProviderName: jsonData.ProviderName,
          CancellationTime,
          CheckinTime,
          CheckoutTime,
          IncludeTablet: IncludeTablet || false,
          StartPlace: {
            ...StartPlace,
            TransferPlaceType: StartPlace.Type,
            Address: {
              label: StartPlaceAddressLabel,
              selected: {},
              suggests: [],
            },
          },
          EndPlace: {
            ...EndPlace,
            TransferPlaceType: EndPlace.Type,
            Address: {
              label: EndPlaceAddressLabel,
              selected: {},
              suggests: [],
            },
          },
        },
        from: {
          label: '',
          selected: {},
          suggests: [],
        },
        to: {
          label: '',
          selected: {},
          suggests: [],
        },
        selectedEmployeesIds: [],
      };
    } else {
      this.state = {
        ...this.state,
        Status: 0,
        CompanyId: 0,
        ProjectId: 0,
        DepartmentId: 0,
        Departments: [],
        JsonData: {
          ...jsonData,
          StartPlace: {
            ...StartPlace,
            TransferPlaceType: StartPlace.Type || TRANSFERTYPES.AIRPORT.TYPE,
            Address: {
              label: StartPlace.Address || '',
              selected: {},
              suggests: [],
            },
          },
          EndPlace: {
            ...EndPlace,
            TransferPlaceType: EndPlace.Type || TRANSFERTYPES.AIRPORT.TYPE,
            Address: {
              label: EndPlace.Address || '',
              selected: {},
              suggests: [],
            },
          },
        },
        from: {
          label: '',
          selected: {},
          suggests: [],
        },
        to: {
          label: '',
          selected: {},
          suggests: [],
        },
        selectedEmployeesIds: [],
      };
    }

    this.setMomentDates(this.state.JsonData, false);
  }

  setMomentDates = (JsonData, isEditing) => {
    const {
      CheckinDate,
      CheckinTime,
      CheckoutDate,
      CheckoutTime,
      CancellationDate,
      CancellationTime,
    } = JsonData;

    this.state.JsonData.CheckinDate = moment(CheckinDate);
    this.state.JsonData.CheckoutDate = moment(CheckoutDate);
    this.state.JsonData.CancellationDate = moment(CancellationDate);

    if (isEditing) {
      this.state.JsonData.CheckinTime = moment(CheckinTime);
      this.state.JsonData.CheckoutTime = moment(CheckoutTime);
      this.state.JsonData.CancellationTime = moment(CancellationTime);
    } else {
      this.state.JsonData.CheckinTime = moment(CheckinTime, DEFAULTTIME);
      this.state.JsonData.CheckoutTime = moment(CheckoutTime, DEFAULTTIME);
      this.state.JsonData.CancellationTime = moment(CancellationTime, DEFAULTTIME);
    }
  };

  handleChangeDescription = (e, field, value) => this.setState({ Description: value });

  handleChangePenalties = (e, field, value) => this.setState({
    Penalties: {
      ...this.state.Penalties,
      [field]: value,
    },
  });

  handleChangeReturnFunds = (e, field, value) => this.setState({
    ReturnFunds: {
      ...this.state.ReturnFunds,
      [field]: value,
    },
  });

  handleChangeSurcharges = (e, field, value) => this.setState({
    Surcharges: {
      ...this.state.Surcharges,
      [field]: value,
    },
  });

  handleAddViewerNumber = () => {
    const { JsonData: { Viewers } } = this.state;

    const newViewersList = Viewers ? [...Viewers] : [];

    newViewersList.push('');

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        Viewers: newViewersList,
      },
    });
  }

  handleRemoveViewerNumber = (idx) => {
    const { JsonData: { Viewers } } = this.state;

    const newViewersList = Viewers.filter((_, index) => index !== idx);

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        Viewers: newViewersList,
      },
    });
  };

  handleChangeViewerInput = (value, idx) => {
    const { JsonData: { Viewers } } = this.state;

    const newViewersList = Viewers ? [...Viewers] : [];

    newViewersList[idx] = value;

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        Viewers: newViewersList,
      },
    });
  }

  handleChangeInput = (value, field, maxLength) => {
    if (value.length > maxLength) return false;
    const part = field.split('.');

    if (part.length === 1) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          [field]: value,
        },
      });
    }

    if (part.length === 3) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          [part[0]]: {
            ...this.state.JsonData[part[0]],
            [part[1]]: {
              ...this.state.JsonData[part[0]][part[1]],
              label: value,
            },
          },
        },
      });
    }

    return this.setState({
      JsonData: {
        ...this.state.JsonData,
        [part[0]]: {
          ...this.state.JsonData[part[0]],
          [part[1]]: value,
        },
      },
    });
  };

  handleChangePriceInput = (e, field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      [field]: value,
    },
    JsonData: {
      ...this.state.JsonData,
      PriceDetails: {
        ...this.state.JsonData.PriceDetails,
        [field]: value,
      },
    },
  });

  handleChangeEditPriceInput = ({ target: { value } }, field) => this.setState({
    JsonData: {
      ...this.state.JsonData,
      PriceDetails: {
        ...this.state.JsonData.PriceDetails,
        [field]: value,
      },
    },
  });

  handleSelectEmployee = async ({ Id }, index) => {
    const { tripService } = this.props;
    const { CompanyId, selectedEmployeesIds, employeesListForPick } = this.state;

    const currentEmployee = await tripService.getEmployeeDataByID(Id);
    const updSelectedEmployeesIds = [...selectedEmployeesIds, Id];
    const updEmployeesListForPick = employeesListForPick.map(emp => (index === emp.id ? { ...emp, ...currentEmployee } : emp));

    const result = tripService.transferTripFieldValidation(FIELDS.EMPLOYEELIST, updSelectedEmployeesIds);

    if (index === 0) {
      this.setState({
        Departments: findDepartments(currentEmployee, CompanyId),
        validation: {
          ...this.state.validation,
          Departments: findDepartments(currentEmployee, CompanyId),
        },
      });
    }

    this.setState({
      EmployeeId: Id || 0,
      validation: {
        ...this.state.validation,
        Employees: result,
      },
      selectedEmployeesIds: updSelectedEmployeesIds,
      employeesListForPick: updEmployeesListForPick,
    });
  };

  handleRemoveEmployeeSuggest = (index) => {
    const { employeesListForPick, DepartmentId, CompanyId, selectedEmployeesIds, Departments } = this.state;
    const { isEditing } = this.props;

    const newEmployeesList = employeesListForPick.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { id: item.id };
      }

      return item;
    });

    const currentEmployeeId = employeesListForPick.find(el => el.id === index).Id;
    const updSelectedEmployeesIds = selectedEmployeesIds.filter(empId => empId !== currentEmployeeId);
    const noEmployees = newEmployeesList.every(({ Id }) => !Id);
    const newDepartment = isEditing ? Departments : findDepartments(newEmployeesList[0], CompanyId);

    this.setState({
      employeesListForPick: newEmployeesList,
      selectedEmployeesIds: updSelectedEmployeesIds,
      DepartmentId: noEmployees || Object.keys(newEmployeesList[0]).length === 0 ? 0 : DepartmentId,
      Departments: noEmployees ? [] : newDepartment,
    });
  }

  handleConfirm = () => {
    const {
      OrderTripItemId,
      CompanyId,
      ServiceType,
      Status,
      JsonData,
      PriceDetails,
      ProjectId,
      DepartmentId,
      selectedEmployeesIds,
      Description,
      Surcharges,
      ReturnFunds,
      Penalties,
      UserAnalytics = [],
      employeesListForPick,
    } = this.state;
    const { addTrip, guidService, onConfirm, isEditing, transfer } = this.props;

    const stateToSave = JSON.parse(JSON.stringify(this.state));
    const Guid = guidService.generate();

    stateToSave.Guid = Guid;

    const paramsForValidation = isEditing ? { Description, ProjectId } : { CompanyId, ProjectId, DepartmentId };

    const { isValid, validation } = this.validationSubmit({ selectedEmployeesIds, JsonData, ...paramsForValidation });

    if (isValid) {
      JsonData.EmployeeIds = selectedEmployeesIds;
      JsonData.Capacity = parseInt(JsonData.Capacity, 10) || 0;

      if (typeof JsonData.CheckinDate !== 'string') JsonData.CheckinDate = JsonData.CheckinDate.format(DATE);
      if (typeof JsonData.CheckinTime !== 'string') JsonData.CheckinTime = JsonData.CheckinTime.format(DEFAULTTIME);
      if (typeof JsonData.CheckoutDate !== 'string') JsonData.CheckoutDate = JsonData.CheckoutDate.format(DATE);
      if (typeof JsonData.CheckoutTime !== 'string') JsonData.CheckoutTime = JsonData.CheckoutTime.format(DEFAULTTIME);
      if (typeof JsonData.CancellationDate !== 'string') JsonData.CancellationDate = JsonData.CancellationDate.format(DATE);
      if (typeof JsonData.CancellationTime !== 'string') JsonData.CancellationTime = JsonData.CancellationTime.format(DEFAULTTIME);

      JsonData.CancellationDate = `${JsonData.CancellationDate} ${JsonData.CancellationTime}`;
      JsonData.CancellationTime = 0;
      JsonData.CheckinDate = `${JsonData.CheckinDate} ${JsonData.CheckinTime}`;
      JsonData.CheckoutDate = `${JsonData.CheckoutDate} ${JsonData.CheckoutTime}`;
      JsonData.StartPlace.Address = JsonData.StartPlace.Address.label;
      JsonData.EndPlace.Address = JsonData.EndPlace.Address.label;
      JsonData.StartPlace.Type = JsonData.StartPlace.TransferPlaceType;
      JsonData.EndPlace.Type = JsonData.EndPlace.TransferPlaceType;
      JsonData.Viewers = filterOnlyNumberArray(JsonData.Viewers);

      const item = {
        OrderTripItemId,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        Status,
        ServiceType,
        JsonData: JSON.stringify(JsonData),
        EmployeesList: employeesListForPick.filter(({ Id }) => !!Id),
        UserAnalytics,
        Guid,
        indexEdited: transfer.indexEdited,
      };

      if (isEditing) {
        item.Description = Description;
        item.Penalties = Penalties.Enabled ? [lodashReplaces.omit(Penalties, FIELDS.ENABLED)] : [];
        item.ReturnFunds = ReturnFunds.Enabled ? [lodashReplaces.omit(ReturnFunds, FIELDS.ENABLED)] : [];
        item.Surcharges = Surcharges.Enabled ? [lodashReplaces.omit(Surcharges, FIELDS.ENABLED)] : [];
      } else {
        item.PriceDetails = PriceDetails;
      }

      addTrip(stateToSave);
      onConfirm(item);
    } else {
      scrollToErrMessage();

      this.setState({
        validation: {
          ...validation,
        },
      });
    }
  };

  handleSelectCompany = ({ id }) => {
    const { tripService, mandatoryProject } = this.props;
    const newMandatory = isMandatory(mandatoryProject, id);
    const result = tripService.transferTripFieldValidation(FIELDS.COMPANYID, id);
    const projectResult = newMandatory ? tripService.transferTripFieldValidation(FIELDS.PROJECTID, null) : '';

    this.setState({
      CompanyId: id,
      Employees: {},
      mandatoryProject: newMandatory,
      validation: {
        ...this.state.validation,
        ProjectId: projectResult,
        CompanyId: result,
        Employees: '',
      },
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? tripService.transferTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : {
        UserAnalytics: updatedAnalyticsValues,
      };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;
    const result = Departments.length ? tripService.transferTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';
    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  validationSubmit = ({
    Description,
    CompanyId,
    ProjectId,
    DepartmentId,
    selectedEmployeesIds,
    JsonData,
  }) => {
    const {
      CheckinDate,
      CheckinTime,
      CheckoutDate,
      CheckoutTime,
      CancellationDate,
      CancellationTime,
      StartPlace,
      EndPlace,
      PriceDetails,
      ArrivalNumber,
      ProviderName,
      CarriageNumber,
    } = JsonData;
    const {
      isEditing,
      analytics,
      projects,
      tripAnalyticsValueIds: tripAnalyticsValues,
      tripService: {
        transferTripFieldValidation,
      },
    } = this.props;
    const {
      Departments,
      mandatoryProject,
      Providers,
      UserAnalytics: serviceAnalyticsValues = [],
    } = this.state;

    const projectMassage = projects.length
      ? transferTripFieldValidation(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    const companyId = isEditing ? '' : transferTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const projectId = mandatoryProject ? projectMassage : '';
    const departmentId = Departments.length ? transferTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId) : '';
    const description = isEditing ? transferTripFieldValidation(FIELDS.DESCRIPTION, Description) : '';

    const base = isEditing ? transferTripFieldValidation(PRICEFIELDSSHORT.BASE, PriceDetails.Base) : '';
    const fee = isEditing ? transferTripFieldValidation(PRICEFIELDSSHORT.FEE, PriceDetails.Fee) : '';
    const tax = isEditing ? transferTripFieldValidation(PRICEFIELDSSHORT.TAX, PriceDetails.Tax) : '';
    const commission = isEditing ? transferTripFieldValidation(PRICEFIELDSSHORT.COMMISSION, PriceDetails.Commission) : '';

    const employees = transferTripFieldValidation(FIELDS.EMPLOYEELIST, selectedEmployeesIds);
    const checkinDate = transferTripFieldValidation(FIELDS.CHECKIN_DATE, CheckinDate);
    const checkinTime = transferTripFieldValidation(FIELDS.CHECKINTIME, CheckinTime);
    const cancelDate = transferTripFieldValidation(FIELDS.CANCELLATION_DATE, CancellationDate);
    const cancelTime = transferTripFieldValidation(FIELDS.CANCELLATION_TIME, CancellationTime);
    const checkoutTime = transferTripFieldValidation(FIELDS.CHECKOUTTIME, CheckoutTime);

    const providers = !Providers.label && !ProviderName.length ? transferTripFieldValidation(FIELDS.PROVIDERS, Providers.label) : '';
    const arrivalNumber = (StartPlace.TransferPlaceType === TRANSFERTYPES.AIRPORT.TYPE || StartPlace.TransferPlaceType === TRANSFERTYPES.RAILWAY.TYPE)
      ? transferTripFieldValidation(FIELDS.ARRIVALNUMBER, ArrivalNumber)
      : '';
    const airportFrom = StartPlace.TransferPlaceType === TRANSFERTYPES.AIRPORT.TYPE
      ? transferTripFieldValidation(FIELDS.AIRPORT_FROM, StartPlace.Address.label)
      : '';
    const airportTo = EndPlace.TransferPlaceType === TRANSFERTYPES.AIRPORT.TYPE
      ? transferTripFieldValidation(FIELDS.AIRPORT_TO, EndPlace.Address.label)
      : '';
    const trainFrom = StartPlace.TransferPlaceType === TRANSFERTYPES.RAILWAY.TYPE
      ? transferTripFieldValidation(FIELDS.TRAIN_FROM, StartPlace.Address.label)
      : '';
    const trainTo = EndPlace.TransferPlaceType === TRANSFERTYPES.RAILWAY.TYPE
      ? transferTripFieldValidation(FIELDS.TRAIN_TO, EndPlace.Address.label)
      : '';
    const arrivalCountry = EndPlace.TransferPlaceType === TRANSFERTYPES.ADDRESS.TYPE
      ? transferTripFieldValidation(FIELDS.ARRIVAL_COUNTRY, EndPlace.Address.label)
      : '';
    const departureCountry = StartPlace.TransferPlaceType === TRANSFERTYPES.ADDRESS.TYPE
      ? transferTripFieldValidation(FIELDS.DEPARTURE_COUNTRY, StartPlace.Address.label)
      : '';
    const carriageNumber = StartPlace.TransferPlaceType === TRANSFERTYPES.RAILWAY.TYPE
      ? transferTripFieldValidation(FIELDS.CARRIAGENUMBER, CarriageNumber)
      : '';

    let checkoutDate;

    if (isMoment(JsonData.CheckinDate) &&
      JsonData.CheckinDate.isValid() &&
      isMoment(JsonData.CheckoutDate) &&
      JsonData.CheckoutDate.isValid()) {
      checkoutDate = transferTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      checkoutDate = transferTripFieldValidation(FIELDS.CHECKOUT_DATE, CheckoutDate);
      checkoutDate = transferTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    }

    const userAnalytics = validateAnalytics(tripAnalyticsValues, serviceAnalyticsValues, analytics);
    const hasUnsetRequiredAnalytics = Object.keys(userAnalytics).some(key => !!userAnalytics[key]);

    if (companyId ||
      projectId ||
      departmentId ||
      description ||
      employees ||
      checkinDate ||
      checkinTime ||
      checkoutDate ||
      checkoutTime ||
      cancelDate ||
      cancelTime ||
      base ||
      fee ||
      tax ||
      commission ||
      hasUnsetRequiredAnalytics ||
      arrivalNumber ||
      airportFrom ||
      airportTo ||
      trainFrom ||
      trainTo ||
      arrivalCountry ||
      departureCountry ||
      providers ||
      carriageNumber
      ) {
      return {
        isValid: false,
        validation: {
          CompanyId: companyId,
          ProjectId: projectId,
          DepartmentId: departmentId,
          MandatoryProject: mandatoryProject,
          Description: description,
          Employees: employees,
          CheckinDate: checkinDate,
          CheckinTime: checkinTime,
          CancellationDate: cancelDate,
          CancellationTime: cancelTime,
          CheckoutDate: checkoutDate,
          CheckoutTime: checkoutTime,
          ArrivalCountry: arrivalCountry,
          DepartureCountry: departureCountry,
          AirportFrom: airportFrom,
          TrainFrom: trainFrom,
          AirportTo: airportTo,
          TrainTo: trainTo,
          ArrivalNumber: arrivalNumber,
          Base: base,
          Fee: fee,
          Tax: tax,
          Commission: commission,
          analytics: userAnalytics,
          CarriageNumber: carriageNumber,
          Providers: providers,
        },
      };
    }

    return {
      isValid: true,
    };
  };

  handleValidate = (field, value) => {
    const { JsonData, validation } = this.state;
    const { tripService: { transferTripFieldValidation }, isEditing } = this.props;

    const { result, resultCheckin, resultCheckout, resultCheckoutLess } = validateDateAndTime(field, value, isEditing, JsonData, transferTripFieldValidation);

    if (resultCheckin || resultCheckout || resultCheckoutLess) {
      this.setState({
        validation: {
          ...validation,
          [field]: result,
          CheckinDate: resultCheckin,
          CheckoutDate: resultCheckout || resultCheckoutLess,
        },
      });
    } else {
      this.setState({
        validation: {
          ...validation,
          [field]: result || '',
          CheckinDate: '',
          CheckoutDate: '',
        },
      });
    }
  };

  handleChangeFinancesEnabled = (e, field, value) => {
    const part = field.split('.');

    return this.setState({
      [part[0]]: {
        ...this.state[part[0]],
        [part[1]]: value,
      },
    });
  };

  handleIncreaseCountEmployees = (index) => {
    const { employeesListForPick } = this.state;
    if (employeesListForPick.length === 6) {
      return;
    }

    this.setState({ employeesListForPick: [...employeesListForPick, { id: index }] });
  };

  handleDecreaseCountEmployees = () => {
    const { employeesListForPick, selectedEmployeesIds } = this.state;
    if (employeesListForPick.length === 1) {
      return;
    }
    const currentEmployeeId = employeesListForPick.find(el => el.id === employeesListForPick.length - 1).Id;
    const updSelectedEmployeesIds = selectedEmployeesIds.filter(empId => empId !== currentEmployeeId);

    this.setState({
      employeesListForPick: employeesListForPick.slice(0, employeesListForPick.length - 1),
      selectedEmployeesIds: updSelectedEmployeesIds,
    });
  }

  handleChangeEmployee = async (value) => {
    await this.props.tripService.autocompleteEmployees(this.state.CompanyId, value);
  }

  handleEmployeeFocus = async () => {
    const { tripService: { autocompleteEmployees } } = this.props;
    const { CompanyId, validation } = this.state;

    await autocompleteEmployees(CompanyId, '');

    this.setState({
      validation: {
        ...validation,
        Employees: parseInt(CompanyId, 10) ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY,
      },
    });
  };

  handleChangePlaceType = (field, value) => {
    const fieldToValidate = field === FIELDS.STARTPLACE ? FIELDS.LOCATIONADDRESS : FIELDS.DESTINATIONADDRESS;

    if (field === FIELDS.STARTPLACE) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          StartPlace: {
            ...this.state.JsonData.StartPlace,
            City: '',
            Country: '',
            TransferPlaceType: value,
            Type: value,
            Address: {
              label: '',
              selected: {},
              suggests: [],
            },
          },
          ArrivalNumber: '',
          [fieldToValidate]: '',
        },
        validation: {
          ...this.state.validation,
          [FIELDS.LOCATIONADDRESS]: '',
          [FIELDS.ARRIVAL_CITY]: '',
          [FIELDS.ARRIVAL_COUNTRY]: '',
          [FIELDS.AIRPORT_FROM]: '',
          [FIELDS.TRAIN_FROM]: '',
          [FIELDS.DEPARTURENUMBER]: '',
        },
      });
    }

    return this.setState({
      JsonData: {
        ...this.state.JsonData,
        EndPlace: {
          ...this.state.JsonData.EndPlace,
          City: '',
          Country: '',
          TransferPlaceType: value,
          Type: value,
          Address: {
            label: '',
            selected: {},
            suggests: [],
          },
        },
        DepartureNumber: '',
        [fieldToValidate]: '',
      },
      validation: {
        ...this.state.validation,
        [FIELDS.DESTINATIONADDRESS]: '',
        [FIELDS.DEPARTURE_CITY]: '',
        [FIELDS.DEPARTURE_COUNTRY]: '',
        [FIELDS.AIRPORT_TO]: '',
        [FIELDS.TRAIN_TO]: '',
        [FIELDS.ARRIVALNUMBER]: '',
      },
    });
  };

  handleSelectSuggestTrain = (field, { Name, Hint }) => {
    const { JsonData } = this.state;

    const part = field.split('.');

    this.setState({
      JsonData: {
        ...JsonData,
        [part[0]]: {
          ...JsonData[part[0]],
          [part[1]]: {
            ...JsonData[part[0]][part[1]],
            label: `${Name} ${Hint}`,
          },
        },
      },
    });
  }

  handleSelectSuggestAir = (field, { City, Country, Name, Code }) => {
    const { JsonData } = this.state;

    const part = field.split('.');

    this.setState({
      JsonData: {
        ...JsonData,
        [part[0]]: {
          ...JsonData[part[0]],
          [part[1]]: {
            ...JsonData[part[0]][part[1]],
            label: `${Code} ${City} ${Country} ${Name}`,
          },
        },
      },
    });
  }

  handleChangeSuggestAirport = (field, validField, value) => {
    if (value.length > MAX_LENGTH_ADDRESS) return;

    const part = field.split('.');

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        [part[0]]: {
          ...this.state.JsonData[part[0]],
          [part[1]]: {
            ...this.state.JsonData[part[0]][part[1]],
            label: value,
          },
        },
      },
      validation: {
        ...this.state.validation,
        [validField]: '',
      },
    }, () => {
      this.props.airService.autocomplete(this.state.JsonData[part[0]][part[1]].label).then((res) => {
        let result = [];

        res.forEach((airport) => {
          result.push(airport);

          if (airport.ChildLocation) result = result.concat(airport.ChildLocation);
        });

        this.setState({
          JsonData: {
            ...this.state.JsonData,
            [part[0]]: {
              ...this.state.JsonData[part[0]],
              [part[1]]: {
                ...this.state.JsonData[part[0]][part[1]],
                suggests: result,
              },
            },
          },
        });
      });
    });
  };

  handleChangeSuggestTrain = (field, validField, value) => {
    if (value.length > MAX_LENGTH_ADDRESS) return;

    const part = field.split('.');

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        [part[0]]: {
          ...this.state.JsonData[part[0]],
          [part[1]]: {
            ...this.state.JsonData[part[0]][part[1]],
            label: value,
          },
        },
      },
      validation: {
        ...this.state.validation,
        [validField]: '',
      },
    }, () => {
      this.props.tripService.autocompleteTrainStation(this.state.JsonData[part[0]][part[1]].label).then((res) => {
        this.setState({
          JsonData: {
            ...this.state.JsonData,
            [part[0]]: {
              ...this.state.JsonData[part[0]],
              [part[1]]: {
                ...this.state.JsonData[part[0]][part[1]],
                suggests: res,
              },
            },
          },
        });
      });
    });
  }

  handleSwapDestination = () => {
    const { JsonData, swapDestinationClicked } = this.state;
    const { EndPlace, StartPlace, ArrivalNumber, DepartureNumber, DestinationAddress, LocationAddress } = JsonData;

    this.setState({
      swapDestinationClicked: !swapDestinationClicked,
      JsonData: {
        ...JsonData,
        StartPlace: {
          ...EndPlace,
        },
        EndPlace: {
          ...StartPlace,
        },
        DepartureNumber: ArrivalNumber,
        ArrivalNumber: DepartureNumber,
        LocationAddress: DestinationAddress,
        DestinationAddress: LocationAddress,
        CheckinDate: '',
        CheckinTime: '',
        CheckoutDate: '',
        CheckoutTime: '',
      },
      validation: {
        ArrivalNumber: '',
        ArrivalCountry: '',
        ArrivalCity: '',
        LocationAddress: '',
        DestinationAddress: '',
        DepartureCountry: '',
        DepartureCity: '',
        DepartureNumber: '',
        AirportFrom: '',
        AirportTo: '',
        TrainFrom: '',
        TrainTo: '',
      },
    });
  }

  handleProviderFocus = async () => {
    const { tripService } = this.props;

    const result = await tripService.getAllProviders(SERVICE_NAME);

    this.setState({
      providersList: result,
    });
  };

  handlerSelectProvider = (value) => {
    const { label } = value;
    const { tripService, isEditing } = this.props;

    if (isEditing && label === '') {
      return;
    }

    const result = tripService.transferTripFieldValidation(FIELDS.PROVIDERS, label);

    this.setState({
      Providers: value,
      JsonData: {
        ...this.state.JsonData,
        ProviderName: label,
      },
      validation: {
        ...this.state.validation,
        Providers: result,
      },
    });
  }

  handleChangeCheckbox = (e, field, value) => {
    this.setState({
      JsonData: {
        ...this.state.JsonData,
        IncludeTablet: value,
        TableSignature: '',
      },
    });
  }

  renderDay(props, currentDate) {
    const todayDate = getMoment();
    const currentDateFormat = getDate(currentDate);

    if (isBeforeDateParameter(currentDate, todayDate)) {
      return <td { ...props } className={ `${props.className} ${styles['date-before']}` }>{ currentDateFormat }</td>;
    }

    return <td { ...props }>{ currentDateFormat }</td>;
  }

  renderCustomFields = (field) => {
    const {
      JsonData,
      JsonData: {
        [field]: {
          TransferPlaceType,
          Type,
          Address,
        },
      },
      showCustomFields,
      validation,
    } = this.state;

    if (!showCustomFields[field]) return null;

    return (
      <CustomFields
        field={ field }
        jsonData={ JsonData }
        showCustomFields={ showCustomFields }
        validation={ validation }
        transferPlaceType={ TransferPlaceType }
        type={ Type }
        address={ Address }
        renderError={ this.renderError }
        onValidate={ this.handleValidate }
        onChangeInput={ this.handleChangeInput }
        onChangePlaceType={ this.handleChangePlaceType }
        onSelectSuggestAir={ this.handleSelectSuggestAir }
        onSelectSuggestTrain={ this.handleSelectSuggestTrain }
        onChangeSuggestAirport={ this.handleChangeSuggestAirport }
        onChangeSuggestTrain={ this.handleChangeSuggestTrain }
      />
    );
  };

  renderEditHistory = () => {
    if (!this.props.isEditing) return null;

    const { Description, validation } = this.state;

    return (
      <div className={ `${styles.row} ${styles.panel}` } >
        <h4>Описание изменения</h4>
        <div className={ styles.row } >
          <Input
            field={ FIELDS.DESCRIPTION }
            value={ Description }
            label={ LABELS.DESCRIPTION }
            onChange={ this.handleChangeDescription }
            onBlur={ () => this.handleValidate(FIELDS.DESCRIPTION, Description) }
            valid={ validation.Description }
          />
        </div>
      </div>
    );
  };

  renderCompany = () => {
    const { companies } = this.props;
    const { CompanyId, validation } = this.state;

    return (
      <SelectCompany
        currentCompanyId={ CompanyId }
        companies={ companies }
        onSelect={ this.handleSelectCompany }
        validationCompany={ validation.CompanyId }
      />
    );
  };

  getActualEmployees = (list, selectedIds) =>
    list.filter(({ Id }) =>
    selectedIds.every(excludeId => excludeId !== Id));

  renderEmployeeSuggest = (el, index) => {
    const {
      employeesListForPick,
      employeesList,
      CompanyId,
      selectedEmployeesIds,
      validation,
    } = this.state;
    const col = employeesListForPick.length > 3 ? employeesListForPick.length : 3;
    return (
      <div
        className={ `${styles[`col-1-${col}`]} ${styles['employee-item-suggest']}` }
        key={ `employee_suggest_${index}` }
      >
        <EmployeeSuggest
          items={ this.getActualEmployees(employeesList, selectedEmployeesIds) }
          selected={ employeesListForPick[index] }
          placeholder={ generateEmployeePlaceholder(index) }
          onRemove={ () => this.handleRemoveEmployeeSuggest(index) }
          onSelect={ item => this.handleSelectEmployee(item, index) }
          onFocus={ this.handleEmployeeFocus }
          onChange={ this.handleChangeEmployee }
          disablePick={ !CompanyId }
          valid={ validation.Employees }
        />
      </div>
    );
  };

  renderPrice = () => {
    if (this.props.isEditing) return null;

    const { PriceDetails: { Base, commission, Fee } } = this.state;

    return (
      <div className={ `${styles.row} ${styles.panel}` }>
        <h4>Цена</h4>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.BASEPRICE }
              value={ Base }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              label={ LABELS.BASEPRICE }
              onChange={ this.handleChangePriceInput }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.COMMISSION }
              value={ commission }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              label={ LABELS.COMMISIONPRICE }
              onChange={ this.handleChangePriceInput }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              field={ PRICEFIELDSSHORT.FEE }
              value={ Fee }
              label={ PRICELABELS.FEE }
              onChange={ this.handleChangePriceInput }
            />
          </div>
        </div>
      </div>
    );
  };

  renderEditPricee = () => {
    const { isEditing } = this.props;
    const { JsonData: { PriceDetails }, validation } = this.state;

    if (!isEditing) {
      return null;
    }

    return (
      <EditPrice
        priceDetails={ PriceDetails }
        onChangeEditPriceInput={ this.handleChangeEditPriceInput }
        onValidate={ this.handleValidate }
        validation={ validation }
      />
    );
  }

  renderOperations = () => {
    if (!this.props.isEditing) return null;

    const { Penalties, ReturnFunds, Surcharges } = this.state;

    return (
      <OperationsFields
        penalties={ Penalties }
        returnFunds={ ReturnFunds }
        surcharges={ Surcharges }
        onChangeFinancesEnabled={ this.handleChangeFinancesEnabled }
        onChangePenalties={ this.handleChangePenalties }
        onChangeReturnFunds={ this.handleChangeReturnFunds }
        onChangeSurcharges={ this.handleChangeSurcharges }
        renderFundsForm={ renderFundsForm }
      />
    );
  };

  renderError = (field) => {
    const { validation } = this.state;

    return (
      validation[field]
        ? <span className={ `error-msg ${styles['error-block']}` }>{ validation[field] }</span>
        : null
    );
  };

  renderProject = () => {
    const { projects } = this.props;
    const { ProjectId, validation, CompanyId, mandatoryProject } = this.state;

    return (
      <SelectProject
        disabled={ !CompanyId }
        currentProjectId={ ProjectId }
        validationProject={ validation.ProjectId }
        mandatoryProject={ mandatoryProject }
        projects={ projects }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  renderDepartment = () => {
    const { Departments, DepartmentId, EmployeeId, validation } = this.state;
    return (
      <SelectDepartment
        departments={ Departments }
        validationDepartment={ validation.DepartmentId }
        currentDepartmentId={ DepartmentId }
        currentEmployeeId={ EmployeeId }
        onSelect={ this.handleSelectDepartment }
      />
    );
  };

  render() {
    const {
      validation,
      employeesListForPick,
      JsonData: { ProviderOrderId, ProviderName, Viewers },
      Providers,
      providersList,
    } = this.state;
    const {
      analytics,
      tripAnalyticsValueIds,
      transfer,
      isEditing,
    } = this.props;

    const data = this.state.JsonData;

    const selectCarClassHtml = (
      <select
        value={ data.CarClass }
        onChange={ ({ target: { value } }) => this.handleChangeInput(value, FIELDS.CARCLASS, MAX_LENGTH) }
      >
        { this.carClassOptions.map((options, key) => (
          <option
            key={ key }
            value={ options.value }
          >
            { options.label }
          </option>
        )) }
      </select>
    );

    const selectProjectsHtml = this.renderProject();
    const selectDepartmentHtml = this.renderDepartment();
    const selectCompanyHtml = this.renderCompany();
    const viewersList = Viewers || [''];

    return (
      <div className={ styles.wrap }>
        { this.renderEditHistory() }
        <div className={ styles.row }>
          <EmployeeFields
            analytics={ analytics }
            transfer={ transfer }
            employeesListForPick={ employeesListForPick }
            tripAnalyticsValueIds={ tripAnalyticsValueIds }
            validation={ validation }
            onSelectAnalytics={ this.handleSelectAnalytics }
            onIncreaseCountEmployees={ this.handleIncreaseCountEmployees }
            onDecreaseCountEmployees={ this.handleDecreaseCountEmployees }
            renderEmployeeSuggest={ this.renderEmployeeSuggest }
            selectCompanyHtml={ selectCompanyHtml }
            selectDepartmentHtml={ selectDepartmentHtml }
            selectProjectsHtml={ selectProjectsHtml }
          />
          <OrderIdFields
            isEditing={ isEditing }
            providersList={ providersList }
            providerName={ ProviderName }
            providers={ Providers }
            validation={ validation }
            providerOrderId={ ProviderOrderId }
            onChangeProvider={ this.handlerSelectProvider }
            onChangeInput={ this.handleChangeInput }
          />
          <div className={ styles.row }>
            <div className={ `${styles.row} ${styles.panel}` }>
              <div className={ `${styles['col-1-10']}` }>
                <span className={ styles.arrow_swap_wrapper }>
                  <div onClick={ this.handleSwapDestination } className={ styles.arrow_swap }>
                    <i className='fa fa-arrows-v' />
                  </div>
                </span>
              </div>
              <div className={ `${styles['col-9-10']}` }>
                <div className={ `${styles.row} ${styles.panel}` }>
                  { this.renderCustomFields(FIELDS.STARTPLACE) }
                </div>
                { this.renderCustomFields(FIELDS.ENDPLACE) }
              </div>
            </div>
          </div>

          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <div className={ styles.select }>
                <div className={ styles.wrap }>
                  <label>Класс машины</label>
                  { selectCarClassHtml }
                </div>
              </div>
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.CAPACITY }
                value={ data.Capacity }
                label={ LABELS.CAPACITY }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onChange={ ({ target: { value } }) => this.handleChangeInput(value, FIELDS.CAPACITY, MAX_LENGTH) }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <Checkbox
              field={ FIELDS.INCLUDETABLET }
              value={ data.IncludeTablet || false }
              label={ LABELS.INCLUDETABLET }
              onChange={ this.handleChangeCheckbox }
            />
          </div>
          <div className={ `${styles.row} ${styles.panel}` }>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.TABLESIGNATURE }
                value={ data.TableSignature }
                label={ LABELS.TABLESIGNATURE }
                onChange={ ({ target: { value } }) => this.handleChangeInput(value, FIELDS.TABLESIGNATURE, MAX_LENGTH_TABLE) }
                disabled={ !data.IncludeTablet }
              />
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.NOTE }
                value={ data.Note }
                label={ LABELS.NOTE }
                onChange={ ({ target: { value } }) => this.handleChangeInput(value, FIELDS.NOTE, MAX_LENGTH_TABLE) }
              />
            </div>
          </div>
          <CancellationFields
            cancellationDate={ data.CancellationDate }
            cancellationTime={ data.CancellationTime }
            validation={ validation }
            onChangeInput={ this.handleChangeInput }
            onValidate={ this.handleValidate }
            renderError={ this.renderError }
          />
          <ViewersFields
            viewers={ viewersList }
            onAddViewerNumber={ this.handleAddViewerNumber }
            onRemoveViewerNumber={ this.handleRemoveViewerNumber }
            onChangeViewerInput={ this.handleChangeViewerInput }
          />
        </div>
        { this.renderPrice() }
        { this.renderOperations() }
        { this.renderEditPricee() }
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ LABELS.CONFIRM }
            />
          </div>
        </div>
      </div>
    );
  }
}

export { TransferForm };
