import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../components/input';
import { FlatButton } from '../../../../../../components/button';

import { FIELDS } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';

import styles from '../../../../styles/form.module.scss';

const LABELS = {
  TITLE: 'Номер наблюдателя',
  ADD_BUTTON: '+ добавить номер наблюдателя',
  REMOVE_BUTTON: '- удалить номер наблюдателя',
};

const MAX_LENGTH_VIEWERS = 5;

const ViewersFields = ({
  viewers,
  onAddViewerNumber,
  onRemoveViewerNumber,
  onChangeViewerInput,
}) => {
  const renderDeleteButton = (idx) => {
    if (idx === 0) return null;

    return (
      <div className={ styles['col-1-3'] }>
        <div className={ styles['add-conditions'] }>
          <FlatButton
            label={ LABELS.REMOVE_BUTTON }
            onClick={ () => onRemoveViewerNumber(idx) }
          />
        </div>
      </div>
    );
  };

  const renderAddButton = () => {
    const disable = viewers.length === MAX_LENGTH_VIEWERS;

    return (
      <div className={ styles['col-1-3'] }>
        <div className={ styles['add-conditions'] }>
          <FlatButton
            label={ LABELS.ADD_BUTTON }
            onClick={ onAddViewerNumber }
            disabled={ disable }
          />
        </div>
      </div>
    );
  };

  const renderViewer = (number, idx) => (
    <div className={ styles.row } key={ `viewers_${idx}` }>
      <div className={ styles['col-1-3'] }>
        <Input
          field={ FIELDS.VIEWERS }
          value={ number }
          type={ COMPONENTS.INPUT.TYPE.TEXT }
          label={ LABELS.TITLE }
          onChange={ (e, field, value) => onChangeViewerInput(value, idx) }
        />
      </div>
      { renderDeleteButton(idx) }
    </div>
    );

  const viewerListHtml = viewers.map((number, idx) => renderViewer(number, idx));

  return (
    <div className={ `${styles.row} ${styles.panel}` }>
      <h4>{ LABELS.TITLE }</h4>
      { viewerListHtml }
      <div className={ styles.row }>
        { renderAddButton() }
      </div>
    </div>
  );
};

ViewersFields.propTypes = {
  viewers: PropTypes.array.isRequired,
  onAddViewerNumber: PropTypes.func.isRequired,
  onRemoveViewerNumber: PropTypes.func.isRequired,
  onChangeViewerInput: PropTypes.func.isRequired,
};

export { ViewersFields };
