import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../components/input';
import SelectWithInput from '../../../../../../components/SelectWithInput';

import { getProviderOldName } from '../../../../../../bi/utils/trip';

import { FIELDS } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';
import { TYPERATRATES } from '../../../../../../bi/constants/account';

import styles from '../../../../styles/form.module.scss';

const MAX_LENGTH = 40;

const LABELS = {
  PROVIDERNAME: 'Название провайдера',
  PROVIDERORDERID: 'Номер заказа провайдера',
};

const OrderIdFields = ({
  providersList,
  providerName,
  providers,
  validation,
  providerOrderId,
  onChangeProvider,
  onChangeInput,
}) => {
  const providerOldName = getProviderOldName(providersList, providerName);

  const currentLabel = (providersList.length && providerOldName) ? providerOldName : providers;

  const providersListAndInput = [...providersList, { id: 0, type: TYPERATRATES.OPTIONAL, value: null, label: '' }];
  return (
    <div className={ `${styles.row} ${styles.panel}` }>
      <div className={ styles['col-1-4'] }>
        <SelectWithInput
          label={ LABELS.PROVIDERNAME }
          items={ providersListAndInput }
          currentItem={ currentLabel }
          onChange={ onChangeProvider }
          typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
          valid={ validation.Providers }
        />
      </div>
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.PROVIDERORDERID }
          value={ providerOrderId }
          label={ LABELS.PROVIDERORDERID }
          onChange={ ({ target: { value } }) => onChangeInput(value, FIELDS.PROVIDERORDERID, MAX_LENGTH) }
        />
      </div>
    </div>
  );
};

OrderIdFields.propTypes = {
  providersList: PropTypes.array.isRequired,
  providerName: PropTypes.object.isRequired,
  providers: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  providerOrderId: PropTypes.number.isRequired,
  onChangeProvider: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
};

export { OrderIdFields };
