import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../../components/checkbox';

import {
  FUNDSFIELDS,
  FUNDSLABELS,
  FUNDSFIELDSTYPES,
} from '../../../../../../bi/constants/trips';

import styles from '../../../../styles/form.module.scss';

const OperationsFields = ({
  penalties,
  returnFunds,
  surcharges,
  onChangeFinancesEnabled,
  onChangePenalties,
  onChangeReturnFunds,
  onChangeSurcharges,
  renderFundsForm,
}) => {
  const { Enabled: PenaltiesEnabled } = penalties;
  const { Enabled: RefundEnabled } = returnFunds;
  const { Enabled: SurchargesEnabled } = surcharges;

  const renderPealtiesEnabled = PenaltiesEnabled ?
    renderFundsForm(penalties, onChangePenalties, FUNDSFIELDSTYPES.PENALTIES) :
    null;

  const renderRefundEnabled = RefundEnabled ?
    renderFundsForm(returnFunds, onChangeReturnFunds, FUNDSFIELDSTYPES.RETURNFUNDS) :
    null;

  const renderSurchargesEnabled = SurchargesEnabled ?
    renderFundsForm(surcharges, onChangeSurcharges, FUNDSFIELDSTYPES.SURCHARGES) :
    null;

  return (
    <div className={ styles.row }>
      <div className={ styles['col-1-3'] }>
        <Checkbox
          field={ FUNDSFIELDS.PENALTIES }
          label={ FUNDSLABELS.PENALTIES }
          value={ penalties.Enabled }
          onChange={ onChangeFinancesEnabled }
        />
        { renderPealtiesEnabled }
      </div>
      <div className={ styles['col-1-3'] }>
        <Checkbox
          field={ FUNDSFIELDS.RETURNFUNDS }
          label={ FUNDSLABELS.RETURNFUNDS }
          value={ returnFunds.Enabled }
          onChange={ onChangeFinancesEnabled }
        />
        { renderRefundEnabled }
      </div>
      <div className={ styles['col-1-3'] }>
        <Checkbox
          field={ FUNDSFIELDS.SURCHARGES }
          label={ FUNDSLABELS.SURCHARGES }
          value={ surcharges.Enabled }
          onChange={ onChangeFinancesEnabled }
        />
        { renderSurchargesEnabled }
      </div>
    </div>
  );
};

OperationsFields.propTypes = {
  penalties: PropTypes.object.isRequired,
  returnFunds: PropTypes.object.isRequired,
  surcharges: PropTypes.object.isRequired,
  onChangeFinancesEnabled: PropTypes.func.isRequired,
  onChangePenalties: PropTypes.func.isRequired,
  onChangeReturnFunds: PropTypes.func.isRequired,
  onChangeSurcharges: PropTypes.func.isRequired,
  renderFundsForm: PropTypes.func.isRequired,
};

export { OperationsFields };
